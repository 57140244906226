/* This fires as soon as the element enters the DOM */
.chat-enter {
    opacity: 0;
}
/* This is where we can add the transition*/
.chat-enter-active {
    opacity: 1;
    transition: all 200ms ease-in;
}
/* This fires as soon as the this.state.showList is false */
.chat-exit {
}
/* fires as element leaves the DOM*/
.chat-exit-active {
    opacity: 0;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.chat-enter-done .contact-dialog-enter {
    animation-name: swipeInLeft;
    animation-duration: 350ms;
}
.chat-enter-done .contact-dialog-enter-active {
}
.chat-enter-done .contact-dialog-exit {
    animation-name: swipeOutLeft;
    animation-duration: 350ms;
}
.chat-enter-done .contact-dialog-exit-active {
}
.chat-enter-done .contact-dialog-exit-done {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
}

.forward .dialog-enter{
    animation-name: swipeInRight;
    animation-duration: 350ms;
}
.forward .dialog-enter-active {
}
.forward .dialog-exit, .forward .dialog-enter-done.hide {
    animation-name: swipeOutLeft;
    animation-duration: 350ms;
}
.forward .dialog-exit-active {
}
.forward .dialog-exit-done {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
}

.backward .dialog-enter, .backward .dialog-enter-done.show {
    animation-name: swipeInLeft;
    animation-duration: 350ms;
}

.backward .dialog-enter-active {
}

.backward .dialog-exit {
    animation-name: swipeOutRight;
    animation-duration: 350ms;
}

.backward .dialog-exit-active {
}

.backward .dialog-exit-done {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
}

@keyframes swipeInLeft {
    from, 60%, 75%, 90%, to {
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }

    60% {
        opacity: 1;
    }

    to {
        opacity: 1;
        transform: none;
    }
}

@keyframes swipeOutLeft {
    from, 60%, 75%, 90%, to {
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    from {
        opacity: 1;
        transform: none;
    }

    60% {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes swipeInRight {
    from, 60%, 75%, 90%, to {
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    from {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }

    60% {
        opacity: 1;
    }

    to {
        opacity: 1;
        transform: none;
    }
}

@keyframes swipeOutRight {
    from, 60%, 75%, 90%, to {
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    from {
        opacity: 1;
        transform: none;
    }

    60% {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }
}