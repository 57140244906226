.root {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bg-lavender {
    background-color: #F0F2FD;
}

.d-flex.center {
    justify-content: center;
    align-items: center;
}

.d-flex.justify.center {
    justify-content: center;
}

.d-flex.align.center {
    align-items: center;
}

.link {
    color: #687dfc;
}

    .link:hover {
        color: #687dfc;
    }
