.notification-toast {
}
/* This fires as soon as the element enters the DOM */
.notification-toast-item-enter {
    animation-name: bounceInRight;
    animation-duration: 700ms;
}
/* This is where we can add the transition*/
.notification-toast-item-enter-active {
    
}
/* This fires as soon as the this.state.showList is false */
.notification-toast-item-exit {
    animation-name: bounceOutRight;
    animation-duration: 350ms;
}
/* fires as element leaves the DOM*/
.notification-toast-item-exit-active {

}

@keyframes bounceInRight {
    from, 60%, 75%, 90%, to {
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    from {
        opacity: 0;
        transform: translate3d(3000px, 0, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0);
    }

    75% {
        transform: translate3d(10px, 0, 0);
    }

    90% {
        transform: translate3d(-5px, 0, 0);
    }

    to {
        transform: none;
    }
}

@keyframes bounceOutRight {
    20% {
        opacity: 1;
        transform: translate3d(-20px, 0, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(2000px, 0, 0);
    }
}

.notification-toast .toast-progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    opacity: 0.7;
    transform-origin: left;
    animation: trackProgress linear 1 forwards;
}

@keyframes trackProgress {
    0% {
        transform: scaleX(1);
    }

    100% {
        transform: scaleX(0);
    }
}