button :focus {
    outline: none !important;
}

.MuiButtonBase-root :focus {
    outline: none !important;
}

.full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

.bg-darkblue {
    background-color: #213089;
}

.bg-applegreen {
    background-color: #C8EAD1;
}