.channel-chat .dialog{
}
/* This fires as soon as the element enters the DOM */
.channel-chat .dialog-enter {
    transform: none;
    transition: transform 350ms;
}
/* This is where we can add the transition*/
.channel-chat .dialog-enter-active {

}
/* This fires as soon as the this.state.showList is false */
.channel-chat .dialog-exit {
    transform: translate3d(-100%, 0, 0);
    transition: transform 350ms;
}
/* fires as element leaves the DOM*/
.channel-chat .dialog-exit-active {
}
.channel-chat .dialog-exit-done {
    transform: translate3d(-100%, 0, 0);
    transition: transform 350ms;
}

.channel-chat .pinned-panel {
}
/* This fires as soon as the element enters the DOM */
.channel-chat .pinned-panel-enter {
    transform: translate3d(-100%, 0, 0);
    transition: transform 350ms;
}
/* This is where we can add the transition*/
.channel-chat .pinned-panel-enter-active {
}
.channel-chat .pinned-panel-enter-done {
    transform: translate3d(-100%, 0, 0);
    transition: transform 350ms;
}
/* This fires as soon as the this.state.showList is false */
.channel-chat .pinned-panel-exit {
    transform: none;
    transition: transform 350ms;
}
/* fires as element leaves the DOM*/
.channel-chat .pinned-panel-exit-active {
}
