.poster {
    display: flex;
    justify-content: center;
    margin-inline: auto;
    align-items: flex-start;
}

    .poster .title {
        width: auto;
        font-size: 2rem;
        display: inline-flex;
        background-color: #c1dfe1;
        padding: 20px;
        border-radius: 14px;
        margin-right: 1rem;
        margin-block: 0.5em;
    }

.posterCard {
    position: relative;
    max-height: 90vh;
    max-width: 63vh;
    margin: auto;
}

    .posterCard .title {
        width: 100%;
        font-size: 2rem;
        display: inline-flex;
        background-color: #c1dfe1;
        padding: 20px;
        border-radius: 14px;
        margin-right: 1rem;
        margin-block: 0.1em 0.5em;
    }
    .posterCard .header {
        display: inline-flex;
        width: 100%;
        align-items: center;
        font-size: 1.2em;
        padding: 0.2em 0.2em 0.8em 0.2em
    }

        .posterCard .header > span, .posterCard .header > a {
            margin-inline-start: 0.6em;
            cursor: pointer;
            color: black;
        }

            .posterCard .header > span:hover, .posterCard .header > a:hover {
                text-decoration: underline;
                color: black;
            }

    .posterCard .content {
        position: relative;
        border-radius: 4px;
        cursor: move;
    }
        .posterCard .content:hover:before {
            position: absolute;
            background-color: rgba(255,255,255,0.1);
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            content: '';
        }
        .posterCard .content > img {
            max-height: 100%;
            max-width: 100%;
            min-width: 600px;
        }
        .posterCard .content > div {
            max-height: 100%;
            max-width: 100%;
            min-width: 800px;
            font-size: 2rem;
            padding: 0.2em 0.2em 0.8em 0.2em
        }         

.posterButtonList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 2em;
}

.posterButton {
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    padding-top: 100%;
    font-size: 0.9rem;
    font-weight: bold;
    background-color: rgba(0,0,0,0.2);
    background-size: cover;
    background-repeat: no-repeat;
    background-clip: padding-box;
    background-position: center center;
}

    .posterButton:hover {
        transition-property: opacity, background-size;
        transition-duration: 0.5s;
        transition-timing-function: ease-out;
        background-size: 140%;
        opacity: 0.8;
    }

        .posterButton:hover > span {
            transition-property: font-size, opacity, background-size;
            transition-duration: 0.5s;
            transition-timing-function: ease-out;
            background-size: 140%;
            opacity: 1;
            font-size: 1rem;
        }

    .posterButton > span {
        display: flex;
        position: absolute;
        inset: 0;
        margin: auto;
        justify-content: center;
        align-items: center;
        opacity: 0;
    }

    .posterCollapse {
        transition-property: max-height, opacity;
        transition-duration: 1s;
        transition-timing-function: ease-out;
        overflow: hidden;
        max-height: 0;
        opacity: 0;
    }

        .posterCollapse.show {
            max-height: calc(150vh + 100px);
            opacity: 1;
        }

        .posterCollapse label {
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            margin-left: -11px;
            margin-right: 16px;
            vertical-align: middle;
            float: right;
            font-size: 1rem;
        }

.prevArrow {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 5vw;
    width: 100px;
    height: 100px;
    max-width: 25vw;
    max-height: 25vw;
    border-radius: 100%;
    color: white;
    border: none;
    background-color: rgba(255,255,255,0.2);
    transition: all .25s ease-in;
    z-index: 2;
    font-size: 2em;
    margin: auto;
}

    .prevArrow:hover {
        color: rgba(0,0,0,0.2);
        background-color: white;
        box-shadow: rgba(0,0,0,0.2) 5px 5px 10px;
    }

        .prevArrow::after {
            content: '◀ ';
            position: relative;
            left: -2px;
        }

.nextArrow {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 5vw;
    width: 100px;
    height: 100px;
    max-width: 25vw;
    max-height: 25vw;
    border-radius: 100%;
    color: white;
    border: none;
    background-color: rgba(255,255,255,0.2);
    transition: all .25s ease-in;
    z-index: 2;
    font-size: 2em;
    margin: auto;
}

    .nextArrow:hover {
        color: rgba(0,0,0,0.2);
        background-color: white;
        box-shadow: rgba(0,0,0,0.2) 5px 5px 10px;
    }

        .nextArrow::after {
            content: '▶';
            position: relative;
            left: 2px;
        }

.posterClose {
    position: absolute;
    top: 15px;
    right: 15px;
    color: white;
    font-size: 3rem;
    font-weight: bold;
    line-height: 0.75;
    cursor: pointer;
}

@media (min-width: 768px) {
    .posterClose {
        top: 30px;
        right: 80px;
        font-size: 5rem;
    }
}
