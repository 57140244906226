.vertical {
    display: flex;
    flex-direction: column;
}


.relative {
    position: relative;
}

.corner-rb {
    position: absolute;
    right: 0px;
    bottom: 0px;
}

.mediaSection {
    display: flex;
    background-color: #191919;
    font-size: 1rem;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
}

    .mediaSection iframe {
        border-radius: 14px;
    }

    .mediaSection.rounded {
        border-radius: 14px !important;
    }

    .mediaSection.outline {
        border: 1px solid #A7A7A7;
        border-radius: 14px;
    }

    .mediaSection .center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mediaSection .mediaText {
        color: #C2C2C2;
        font-weight: bold;
        text-align: center;
        font-size: 1.2rem;
    }

    .mediaSection .mediaButton {
        border: none;
        width: 243px;
        padding: 14px;
        color: black;
        font-size: 1.2rem;
        background-color: #16B0D8;
        border-radius: 5px;
        box-shadow: rgba(0,0,0,0.16) 0px 3px 6px inset;
    }

    .mediaSection .mediaButton.primary {

    }

        .mediaSection .mediaButton.secondary {
            border: 1px solid #707070;
            width: 204px;
            padding: 7px;
            color: #C2C2C2;
            background-color: transparent;
        }
            .mediaSection .mediaButton.secondary.corner-rb {
                right: 264px;
                bottom: 154px;
            }


    .mediaSection .media {
        width: 100%;
        max-height: 702px;
        margin: 2px;
        flex-wrap: wrap;
        overflow-y: scroll;
    }


.video {
    position: relative;
    background-color: #3E3E3E;
    text-align: center;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0px 0px 70px 20px rgb(0 0 0 / 50%)
}
    .video video {
        border-radius: 14px;
    }

    .video .avatar {
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        justify-content: center;
        align-items: center;
        color: rgba(0,0,0,0.75);
    }
        .video .avatar .name {
            margin: 0.5rem 0rem 0.125rem 0rem;
            font-size: 1.375rem;
            color: rgba(0,0,0,1);
        }

    .video .micOff {
        position: absolute;
        top: 1em;
        right: 1em;
        z-index: 1;
        background-color: #707070;
        border-radius: 50%;
        padding: 12px;
    }
        .video .micOff svg {
            color: white;
            font-size: 1.5rem;
        }

.mediaBar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mediaMode {
    position: absolute;
    top: -1.5rem;
    right: 0px;
    z-index: 1;
}

.mediaModeTab {
    border: none;
    background-color: #727272;
    color: white;
}

.icon {
    margin: 10px 8px;
}

    .icon svg {
        color: white;
        font-size: 2rem;
    }

.backdrop {
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
}

.backdrop {
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
}

.dialog {
    display: flex;
    color: #707070;
    font-size: 1.25rem;
    background-color: #E8E8E8;
}

.enterBtn {
    display: flex;
    position: absolute;
    right: 0px;
    top: -2.5em;
    border-radius: 6px;
    font-size: 1.15rem;
    background-color: #7F7F7F;
    color: #fff;
    border: none;
    padding: 6px 24px;
    align-items: center;
    justify-content: center;
}

    .enterBtn .hint {
        display: flex;
        position: absolute;
        right: 0px;
        top: -1.3em;
        font-size: 16px;
        font-style: italic;
        color: #94b65b;
        align-items: center;
        justify-content: center;
    }

    .enterBtn svg {
        font-size: 1.375rem;
    }

.exitBtn {
    display: flex;
    position: absolute;
    right: 0px;
    border-radius: 6px;
    font-size: 1.15rem;
    background-color: #7F7F7F;
    color: #fff;
    padding: 6px 8px;
    align-items: center;
    justify-content: center;
    bottom: -4rem;
}
    .exitBtn svg {
        font-size: 1.375rem;
    }

