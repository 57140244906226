.notification-bell .popper {
    z-index: 1;
}

    .notification-bell .popper .arrow {
        top: 0;
        left: 0;
        margin-top: -0.9em;
        width: 3em;
        height: 1em;
    }
        .notification-bell .popper .arrow::before {
            border-width: 0 1em 1em 1em;
            border-color: transparent transparent #fff transparent;
        }

.notification-bell .arrow {
    position: absolute;
    font-size: 7px;
    width: 3em;
    height: 3em;
}
    .notification-bell .arrow::before {
        content: '';
        margin: auto;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
    }